<script>
import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import ContactForm from "./Form.vue";

export default {
  page: {
    title: "Contacts",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      items: [],
    };
  },
  components: {
    Layout,
    ContactForm
  },
};
</script>

<template>
  <Layout>

      <div class="container mt-4">
          <div class="row justify-content-center">
              <div class="col-lg-8">
                  <div class="text-center mb-5">
                      <h3 class="mb-3 fw-semibold">{{ $t('t-contacts-title') }}</h3>
                      <p class="text-muted mb-4 ff-secondary">{{ $t('t-contacts-description') }}</p>
                  </div>
              </div>
          </div>

          <div class="row gy-4">
              <div class="col-lg-6 offset-lg-3">
                <ContactForm></ContactForm>
              </div>
          </div>

      </div>

  </Layout>
</template>